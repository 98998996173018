var globalCommon;

(function($) {
  "use strict";
  /*!
    * global Variable
    */
  var deviceFlag='pc';
  // var parameterList=[];
  var resizeTimer;
  var paramStr = location.search;
  var paramArray = [];
	var scroll_distance = 0;


  var Common={
    /*・・・・・・・・・・・各種実行タイミング・・・・・・・・ */
    /*初回--------------------------------------------*/
    initialize:function(){
      return Common;
    },
    /*レンダリング（DOMツリー読み込み）後-------------------*/
    render:function(){
      Common.changeDevice();
			Common.setBreadCrumb();
      Common.setUpAccordion();
			Common.switchContent();
      Common.setUpTab();
      Common.setUpEqualHeights();
			Common.openNav();
			Common.openNavSp();
			Common.changeTsSlide();
			Common.scrollHeader();
			Common.cookieModal();
			Common.changeH1();
			//Common.changeTsImg();
			Common.setUpSlider();
			Common.setUpGraphicSlider();
			Common.setUpSelectSlide();
			Common.slideTopTopics();
			Common.setUpWorksDetailSlider();/*実績紹介 詳細*/
			Common.historyScroll();/*歴史ページ追従ナビ挙動*/
			Common.defaultFooter();
      return Common;
    },
    /*ロード（リソース読み込み）後-------------------------*/
    onLoad:function(){
      // Common.setUpEqualHeights();
      Common.setUpReturntopLink();
			Common.changeSearchBtn();
			Common.hoverNavi();
			Common.changeMv();
			Common.matchHeight();
			Common.changeTsImg();
			Common.setMoreViewBox();
			Common.dialog();
			Common.setUpAnkerLink();
			Common.setUpUrlAnkerLink();
      return Common;
    },
    /*スクロールイベント--------------------------------- */
    scroll:function(){
      Common.setUpReturntopLink();

      return Common;
    },
    /*リサイズ時---------------------------------------- */
    resize: function() {
      Common.resizedTimer();
			Common.changeTsSlide();
			Common.changeTsImg();
			Common.slideTopTopics();
			//Common.matchHeight();

      return Common;
    },
    resizedTimer: function() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(Common.resized, 150);
    },
    resized:function(){
      Common.changeDevice();
      Common.setUpEqualHeights();
      Common.setUpReturntopLink();

      return Common;
    },

    /*※関数------------------------------------------*/
    changeDevice:function(){
      var winW=window.innerWidth;
      if(winW>=768){
        deviceFlag='pc';
      }
      else{
        deviceFlag='sp';
      }
    },

    /**
     * Set up accordion.
     */
    setUpAccordion: function() {
			var parents = document.querySelectorAll('.js-accordion');
			if(parents){
				Array.prototype.forEach.call(parents, function (item, index) {
					var contents = item.querySelector('.js-accordion-target');
					var head = item.querySelector('.js-accordion-trigger');
					var flag = 'false';

						// var element = item.getElementsByClassName("js-accordion")[0];
						if (item.hasAttribute('aria-expanded') == false) {
							item.setAttribute('aria-expanded', flag);
							$(contents).hide();
						} else {
							flag = 'true';
							// $(contents).css("height",$(this).height()+"px");
							// $(contents).slideDown();
						}
						head.addEventListener('click', function () {
							if (flag == 'false') {
								$(contents).slideDown();
								flag = 'true';
							} else {
								$(contents).slideUp();
								flag = 'false';
							}
							item.setAttribute('aria-expanded', flag);
						});
				});
			}
    },

    /**
     * Set up tab.
     */
    setUpTab: function() {
      var wrapClass = '.js-tab',
          triggerClass = '.js-tab-trigger',
          targetClass = '.js-tab-target';

      if(
        !$(wrapClass).length ||
        !$(wrapClass).find(triggerClass).length ||
        !$(wrapClass).find(targetClass).length
      ) { return false; }

      var currentFlag = 'is-current',
          activeFlag = 'is-active',
          initializedFlag = 'is-initialized';

      var state = [];

      // $trigger が stateIndex のタブにおける何番目のトリガーかを取得
      var currentIndex = function(stateIndex, $trigger) {
        var current;

        state[stateIndex].trigger.each(function(i) {
          if((typeof current !== 'number') && ($(this)[0] === $trigger[0])) {
            current = i;
          }
        });

        return current;
      };

      // stateIndex のタブを triggerIndex で指定された内容に切り替える
      var changeTab = function(stateIndex, triggerIndex) {
        if(state[stateIndex].current === triggerIndex) { return false; }

        var $currentTrigger = state[stateIndex].trigger.eq(triggerIndex),
            $target = $($currentTrigger.attr('href'));

        if($target.length) {
          state[stateIndex].trigger.removeClass(currentFlag);
          state[stateIndex].target.removeClass(activeFlag);

          $currentTrigger.addClass(currentFlag);
          $target.addClass(activeFlag);

          Common.setUpEqualHeights($target);

          state[stateIndex].current = triggerIndex;
        }
      };

      // 初期化処理
      var initialize = function(stateIndex) {
        state[stateIndex] = { wrap: $(this) };
        state[stateIndex].trigger = state[stateIndex].wrap.find(triggerClass);
        state[stateIndex].target = state[stateIndex].wrap.find(targetClass);

        if(
          state[stateIndex].trigger.length &&
          state[stateIndex].target.length
        ) {
          var $currentedTrigger = state[stateIndex].trigger.filter('.' + currentFlag);


          Common.getParam();
          if ( paramArray.tab ) {
            $currentedTrigger = state[stateIndex].trigger.filter(function (index, elem) {
              if ( paramArray.tab === elem.hash.slice(1) ) {
                return index;
              }
            });
          }

          changeTab(
            stateIndex,
            ($currentedTrigger.length)?currentIndex(stateIndex, $currentedTrigger):0
          );

          state[stateIndex].trigger
            .off('click.smoothscroll')
            .on('click', function(e) {
              changeTab(stateIndex, currentIndex(stateIndex, $(this)));

              e.preventDefault();
            });

          state[stateIndex].wrap.addClass(initializedFlag);
        }
      };

      // 実行
      $(wrapClass).each(initialize);
    },

    // onLoad functions ////////////////////////////////////
    /**
     * Set up equal heights.
     */
    setUpEqualHeights: function($parent) {
      var targetSelector = '.js-equal-heights';

      if(!$parent) { $parent = $('body'); }

      var $target = $parent.find(targetSelector);

      if(!$target.length) { return false; }

      var dataGroup = 'group',
          dataForce = 'force';

      var state;

      // 高さそろえ
      var setHeight = function() {
        var keys = Object.keys(state);

        var setHighest = function(stateItem) {
          if(!stateItem.height) { return false; }

          for(var i = 0; i < stateItem.data.length; i++) {
            stateItem.data[i].innerHeight(stateItem.height);
          }
        };

        for(var i = 0; i < keys.length; i++) {
          setHighest(state[keys[i]]);
        }
      };

      // 高さチェック
      var isInitialized = function() {
        var keys = Object.keys(state),
            initialized = true;

        var getHighest = function(stateItem) {
          for(var i = 0; i < stateItem.data.length; i++) {
            var thisHeight = Math.ceil(stateItem.data[i].innerHeight());

            if(thisHeight !== stateItem.height) {
              if(initialized && (stateItem.height !== 0)) {
                initialized = false;
              }

              if(thisHeight > stateItem.height) {
                stateItem.height = thisHeight;
              }
            }
          }
        };

        for(var i = 0; i < keys.length; i++) {
          var stateItem = state[keys[i]];

          stateItem.height = 0;

          getHighest(stateItem);
        }

        return initialized;
      };

      // グルーピング
      var setState = function() {
        var $target = $(this),
            group = $target.data(dataGroup),
            force = $target.data(dataForce);

        if($target.is(':visible')) {
          var groupName =
            (!force?$target.offset().top:'') + (group?('_g_' + group):'');

          if(!state[groupName]) {
            state[groupName] = { data: [$target] };
          }

          else {
            state[groupName].data.push($target);
          }
        }
      };

      var initialize = function() {
        state = {};
        $target.each(setState);

        if(!isInitialized()) {
          setHeight();
          initialize();
        }
      };

      $target.innerHeight('');
      initialize();
    },

    /**
     * Set up ReturnTop Link.
     */
    setUpReturntopLink: function() {
      var targetClassName = 'js-return-top';

      if($('.' + targetClassName).length === 0) { return false; }

      var showFlag = 'is-show',
          fixedFlag = 'is-fixed',
          hideTriggerClass = '.js-return-top__stopTrigger';

      //表示判定
      var isActive = function() {
        var scrollOffset = $(window).scrollTop(),
            windowHeight = window.innerHeight,
            bottomPosition = scrollOffset + windowHeight;

        var isVisible = bottomPosition > windowHeight,
            isFixed = false;

        if($(hideTriggerClass).length !== 0) {
          isFixed = bottomPosition < $(hideTriggerClass).offset().top;
        }

        return [isVisible, isFixed];
      };

      //表示/非表示切り替え
      var switchLink = function() {
        if(isActive()[0] !== $('.' + targetClassName).hasClass(showFlag)) {
          if(isActive()[0]) {
            $('.' + targetClassName).addClass(showFlag);
          }

          else {
            $('.' + targetClassName).removeClass(showFlag);
          }
        }

        if(isActive()[1] !== $('.' + targetClassName).hasClass(fixedFlag)) {
          if(isActive()[1]) {
            $('.' + targetClassName).addClass(fixedFlag);
          }

          else {
            $('.' + targetClassName).removeClass(fixedFlag);
          }
        }
      };

      switchLink();
    },

    setUpAnkerLink: function () {
      var headH = $('.l-header').outerHeight();
      var animeSpeed = 500;

      $('a[href^="#"]')
        .on('click.smoothscroll', function(event) {
          event.preventDefault();
          var href = $(this).attr('href');
          var target = $(href === '#' || href === '' ? 'html' : href);
          var position = target.offset().top - headH;
					console.log(position);
          $('body,html').animate({ scrollTop: position }, animeSpeed, 'swing');
          history.pushState(null, null, href);
          return false;
        });
    },

    setUpUrlAnkerLink: function () {
      var headH = 100;
			var headHSp=80;
      var animeSpeed = 500;
      var urlHash = location.hash;

      // header要素の高さがスクロール後、変わる場合は
      // 下記にて各画面幅毎に指定してください。
      // if (deviceFlag === 'pc') {
      //   headH = 100;
      // } else {
      //   headH = 50;
      // }
			/*
      window.addEventListener('resize', function () {
        headH = $('.l-header').outerHeight();
      });*/
			if(deviceFlag=='sp'){
				headH=headHSp;
			}
			setTimeout(executionSmoothScroll, 0);

			function executionSmoothScroll () {
				if (urlHash) {
					$('body,html').scrollTop(0);
					var target = $(urlHash);
					var position = target.offset().top;
					if($(target)){
						console.log(true);
						$.when(
							$("html, body").animate({
								scrollTop: position
							}, 400, "swing"),
						).done(function() {
							var diff = target.offset().top;
							if (diff === position) {
							} else {
								$("html, body").animate({
									scrollTop: diff
								}, 10, "swing");
							}
						});
					}
				}
			}

    },

    getParam: function () {
      if (paramStr) {
        var temp = paramStr.replace('?', '');
        var temp1 = temp.split('&');
        Array.prototype.forEach.call(temp1, function (item) {
          var temp2 = item.split('=');
          paramArray[temp2[0]] = temp2[1];
        });
      }
    },
    setParam: function () {
      var paramStr = '?';

      var paramKeys = Object.keys(paramArray);
      if (paramKeys.length) {
        Array.prototype.forEach.call(paramKeys, function (item, index) {
          if (index !== 0) {
            paramStr += '&';
          }
          paramStr += item + '=' + paramArray[item];
        });
        history.replaceState({}, '', paramStr);
      }
    },

		setBreadCrumb:function(){
			var element = document.querySelector('.l-breadcrumbs');
			if(element){
				var child=element.querySelector('ol');
				var childWidth;
				var parentWidth;

				getLength();

				window.addEventListener('resize',function(){
					getLength();
				})


				function getLength(){
					parentWidth=element.clientWidth - 60;
					childWidth=child.clientWidth;
					if(parentWidth<childWidth){
						element.classList.add('is-overflow');
						child.style.overflow="hidden";
					}
					else {
						element.classList.remove('is-overflow');
						child.style.overflow="visible";
					}
				}
			}
		},

    changeSearchBtn: function () {
			if ($(window).width() > 767){
				$(".l-header .l-header-search__btn").click(function(){
					$(".search-box-inner").addClass("search-open");
					if(location.pathname.indexOf('/en/')!=-1){
						$('.l-header input').attr('placeholder', 'Enter keyword');
					}
					else {
						$('.l-header input').attr('placeholder', 'サイト内を検索');
					}
					setTimeout(function(){
						$('.l-header .l-header-search__btn').attr('form', 'search');
						$('.l-header button.l-header-search__btn').css('display','none');
						$('.l-header input[type="submit"].l-header-search__btn').attr('aria-expanded', 'true');
						$('.l-header input[type="submit"].l-header-search__btn').attr('disabled', false);
						//$('.l-header .l-header-search__btn').attr('type', 'submit');
					},500);
				});
			}
		},
    hoverNavi: function () {
			$(".l-global-nav__menu li").hover(
				function () {
					$(this).children(".l-global-nav__dropMenu").addClass("active");
				},
				function () {
					$(this).children(".l-global-nav__dropMenu").removeClass("active");
				}
			);
		},
    openNav: function () {
			var menuButton = $(".l-menu-button");
			var lHeader = $(".l-header");
			$(window).resize(function () {
				menuButton.addClass("js-openNav-default").removeClass("js-openNav-open").removeClass("js-openNav-close");
				lHeader.removeClass("js-openNav").removeClass("js-closeNav");
			});
			menuButton.on("click", function(){
				if (menuButton.hasClass('js-openNav-default')) {
					menuButton.removeClass("js-openNav-default").addClass("js-openNav-open");
					lHeader.addClass("js-openNav");
				} else if (menuButton.hasClass('js-openNav-open')) {
					menuButton.removeClass("js-openNav-open").addClass("js-openNav-close");
					lHeader.addClass("js-closeNav").removeClass("js-openNav");
				} else if (menuButton.hasClass('js-openNav-close')) {
					menuButton.removeClass("js-openNav-close").addClass("js-openNav-open");
					lHeader.addClass("js-openNav").removeClass("js-closeNav");
				}
			});
		},
    openNavSp: function () {
			$(".accordion-sp01").on("click", function() {
				if ($(window).width() < 768){
					// header
					$(this).next('.l-menu-nav__subList').slideToggle();
					// footer
					$(this).next('.l-footer-nav__subList').slideToggle();
					$(this).toggleClass("js-openNav-sp");
				}
			});
			$(".accordion-sp02").on("click", function(e) {
				if ($(window).width() < 768){
					e.preventDefault();
					$(this).next('ul').slideToggle();
					$(this).toggleClass("js-openNav-sp");
				}
			});
		},
    scrollHeader: function () {
			var scrollTop_Flag = true;
			var header = $(".l-header");
			window.addEventListener('scroll', function(){
				scrollFunc();
			});
			scrollFunc();
			function scrollFunc(){
				scroll_distance = window.scrollY;
				if (scroll_distance == 0) {
					header.addClass("js-top");
					scrollTop_Flag = true;
				} else {
					header.removeClass("js-top");
					scrollTop_Flag = false;
				}
			}
		},
		cookieModal: function(){
			var domain = document.domain;
			var cookieModal=document.querySelector('.js-cookie');
			if(cookieModal&&domain.includes('search-kumagaigumi.dga.jp')==false){
				var enter=cookieModal.querySelector('.js-cookie__enter');
				var closeTrigger=cookieModal.querySelector('.js-cookie__close');
				var cookie=$.cookie("user");

				if(cookie){
					cookieModal.setAttribute('aria-expanded','false');
				}
				else{
					cookieModal.setAttribute('aria-expanded','true');
				}
				enter.addEventListener('click',function(){
					$.cookie("user", "accept", { domain:domain,path:'/',expires: 400 });
					cookieModal.setAttribute('aria-expanded','false');
				})
				closeTrigger.addEventListener('click',function(){
					cookieModal.setAttribute('aria-expanded','false');
				})
			}
		},
    changeH1: function () {
			if ( window.document.body.id === 'p-top' ) {
				$('div.l-header-logo').replaceWith(function() {
					var tag_class = $(this).attr("class");
					var tag_id = $(this).attr("id");

					if ( tag_class == null) {
						var tag_class_plus =[];
					} else {
						var tag_class_plus = 'class="'+tag_class+'"';
					}
					if ( tag_id == null ) {
						var tag_id_plus =[];
					}
					else {
						var tag_id_plus = 'id="'+tag_id+'"';
					}
					$(this).replaceWith('<h1 '+tag_id_plus+'  '+tag_class_plus+'>'+$(this).html()+'</h1>');
				});
			}
		},
    changeMv: function () {
			setTimeout(() => {
				$('.p-top-mv').addClass("js-changeMv");
			}, "1000");
			setTimeout(() => {
				$(".l-header").addClass("js-header");
			}, "2200");
			setTimeout(() => {
				$(".p-top-mv__firstImg").addClass("js-changeMvImg");
			}, "3800");
			setTimeout(() => {
				$('.p-top-mv').addClass("js-changeMvBlueBg");
			}, "5500");
			setTimeout(() => {
				$(".p-top-mv").addClass("js-changeLastMv");
			}, "7000");
		},
    changeTsImg: function () {
			var tsImg=$(".js-changeTsImg-sp.spOnly");
			if(tsImg.length>0) {
				if ($(window).width() < 768){
					tsImg.slick({
						autoplay:true,
						autoplaySpeed: 0,
						speed:5000,
						cssEase:"linear",
						infinite:true,
						swipe: false,
						variableWidth: true,
					});
				}
			};
		},
		setUpSlider: function () {
			var slider=$(".p-top-slider__list");
			if(slider.length>0) {
				slider.slick({
					slidesToShow:4,
					infinite:true,
					slidesToScroll:1,
					responsive: [{
						breakpoint: 767,
						settings: {
							// slidesToShow: 1,
							// infinite:true,
							// slidesToScroll:1,
							// arrows:false,
							// dots:true,
							// centerMode: true,
							// variableWidth:true,

							// sliderじゃない時
							slidesToShow: 2,
							arrows:false,
						}
					}],
				});
			}
		},
		slideTopTopics: function () {
			var topTopics=$(".p-top-topics__list.spOnly");
			if(topTopics.length>0) {
				topTopics.slick({
					slidesToShow:1,
					infinite:false,
					slidesToScroll:1,
					variableWidth:true,
				});
			}
		},

		changeTsSlide:function(){
			var element=document.querySelector('.js-changeTsSlide');
			if ($(window).width() > 767){
				if(element){
					var triggers=element.querySelectorAll('.js-changeTsSlide-triggers > li.js-changeTsSlide-trigger');
					var contents=element.querySelectorAll('.js-changeTsSlide-contents > li');
					var prev=0;
					var current=0;
					var ua=getUa();
					var triggerEvent;
					init();
					if(ua=='desktop'){
						triggerEvent='mouseenter';
					}
					else {
						triggerEvent='click';
					}

					$(window).resize(function () {
						contents[current].classList.remove('is-enter');
						element.querySelectorAll('.js-changeTsSlide-contents > li.l01')[current].classList.add('is-enter');
					});

					Array.prototype.forEach.call(triggers,function(item,index){
						var sethover;
						if(ua!='desktop'){
							item.querySelector('a').addEventListener('click',function(e){
								e.preventDefault();
							})
						}
						item.addEventListener(triggerEvent,function(){
							sethover=setTimeout(function(){
								resetTrigger();
								item.setAttribute('aria-selected','true');
								if(current!=index){
									resetContents('exit');
									contents[prev].classList.add('is-exit');
									setTimeout(function(){
										contents[prev].classList.remove('is-exit');
									},800)
									current=index;
									resetContents('enter');
									contents[current].classList.add('is-enter');
									prev=current;
								}
							},);
						})
						item.addEventListener('mouseleave',function(){
							clearTimeout(sethover);
						})
					})
					function init(){
						resetTrigger();
						triggers[0].setAttribute('aria-selected','true');
						contents[0].classList.add('is-enter');
					}
					function resetTrigger(){
						Array.prototype.forEach.call(triggers,function(item){
							item.setAttribute('aria-selected','false');
						})
					}
					function resetContents(event){
						Array.prototype.forEach.call(contents,function(item,index){
							if(current!=index){
								item.classList.remove('is-'+event);
							}
						})
					}
					function getUa(){
						const ua = navigator.userAgent;
						if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
							return 'mobile';
						}else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
							return 'tablet';
						}else{
							return 'desktop';
						}
					}
				}
			};
		},

		// cm2023
		setUpGraphicSlider: function () {
			var slider=$(".p-cm2023-slider__list");
			if(slider.length>0) {
				slider.slick({
					slidesToShow:1,
					infinite:false,
					slidesToScroll:1,
					variableWidth:true,
					dots: true,
				});
			}
		},

		// medium-term
		setUpSelectSlide: function () {
			var parent =$('.js-selectSlide');

			if(parent){
				var slider=parent.find(".js-selectSlide-slider");
				var trigger=parent.find(".js-selectSlide-select");
				slider.eq(0).on("init", function (event, slick) {
					$(this).append('<div class="slick-num"><span class="now-count"></span> / <span class="all-count"></span></div>');
      		$(".now-count").text(slick.currentSlide + 1); // 現在のスライド番号(+1が無いと0からスタートしてしまう)
      		$(".all-count").text(slick.slideCount); // スライドの総数
				}).slick({
						slidesToShow:1,
						infinite:false,
						slidesToScroll:1,
						fade:true,
				}).on("beforeChange", function (event, slick, currentSlide, nextSlide) {
      		$(".now-count").text(nextSlide + 1); // 現在のスライド番号の次のスライドになったら番号を+1
				});
				trigger.on('change',function(){
					var pageNum=Number(this.value);
					slider.eq(0).slick("slickGoTo", pageNum, false);
				})
			}
		},

		historyScroll:function(){
		var content = document.querySelector('.js-history-nav-content');
		if(content){
			var nav = document.querySelector('.js-history-nav');
			var foot = document.querySelector('.js-history-end');
			var navCurrent=nav.querySelectorAll('ul > li');
			var navContent = content.querySelectorAll('.p-history-section');

			var contentStart=0;
			var contentEnd=0;
			var scrollY=window.scrollY;
			var contentHeadArray=[];
			var contentBottomArray=[];
			var currentFlagArray=[];

			init();
			navDisplay(scrollY,contentStart,contentEnd);
			navCurrentInit();
			navCurrentSet();

			window.addEventListener('scroll',function(){
				var scrollY=window.scrollY;
				scrollY=window.scrollY;
				navDisplay(scrollY,contentStart,contentEnd);
				navCurrentInit();
				navCurrentSet();
			})

			window.addEventListener('resize',function(){
				scrollY=window.scrollY;
				init();
				navDisplay(scrollY,contentStart,contentEnd);
				navCurrentInit();
				navCurrentSet();
			})

			function init(){
				contentStart=scrollY+content.getBoundingClientRect().y;
				contentEnd=scrollY+foot.getBoundingClientRect().y;
			}

			function navDisplay(scrollY,start,end) {
				if(start<scrollY && scrollY+window.innerHeight<end + 200){
					nav.setAttribute('aria-expanded','true');
				}
				else {
					nav.setAttribute('aria-expanded','false');
				}
			}
			function navCurrentInit(){
				Array.prototype.forEach.call(navContent,function(item,index){
					contentHeadArray[index]=scrollY+item.getBoundingClientRect().y - 10;
					currentFlagArray[index]=false;
				})
				Array.prototype.forEach.call(contentHeadArray,function(item,index){
					if(index < contentHeadArray.length - 1){
						contentBottomArray[index] = contentHeadArray[index + 1];
					}
					else {
						contentBottomArray[index]=contentEnd;
					}
				})
			}
			function navCurrentSet() {
				Array.prototype.forEach.call(contentHeadArray,function(item,index){
					if(scrollY >item){
						currentFlagArray[index]=true;
					}
				})
				Array.prototype.forEach.call(navCurrent,function(item,index){
					if(currentFlagArray[index]==true){
						item.setAttribute('aria-past','true');
					}
					else {
						item.setAttribute('aria-past','false');
					}
					if(contentHeadArray[index]<scrollY&&scrollY<contentBottomArray[index]){
						item.setAttribute('aria-selected','true');
					}
					else {
						item.setAttribute('aria-selected','false');
					}
				})
			}
		}
		},
		/*実績紹介 詳細*/
		setUpWorksDetailSlider: function () {
			var slider=$(".p-detail-slider__list");
			if(slider.length>0) {
				slider.slick({
					slidesToShow:1,
					infinite:false,
					fade: true,
					slidesToScroll:1,
					dots:true,
				});
			}
		},

		// susAccordion:function(){
		// 	var elements=document.querySelectorAll('.js-accordion');
		// 	if(elements){
		// 		Array.prototype.forEach.call(elements,function(item,index){
		// 			var trigger=item.querySelector('.js-accordion-trigger');
		// 			var target=item.querySelector('.js-accordion-target');
		// 			setAccordion(trigger,target);
		// 		})
		// 		function setAccordion(trigger,target){
		// 			$(target).hide();
		// 			trigger.setAttribute('aria-expanded','true');
		// 		}
		// 	}
		// },
		matchHeight:function(){
			var footLists=document.querySelectorAll('.l-foot-list > li > a,.l-foot-list > li > div');
			if(footLists){
				$(footLists).matchHeight({
					remove:true,
				});
				$(footLists).matchHeight({
					byRow:true,
				});
			}
			var topicsContent=document.querySelectorAll('.p-top-topics .slick-slide a[data-mh="topics"]');
			setTopicsContentHeight();
			window.addEventListener('resize',function(){
				setTopicsContentHeight();
			})
			function setTopicsContentHeight(){
				var timer=setInterval(function(){
					topicsContent=document.querySelectorAll('.p-top-topics .slick-slide a[data-mh="topics"]');

					if(topicsContent.length>0){
						var maxHeight = 0;
						$(topicsContent).each(function(index) {
							if ($(this).height() > maxHeight) {
								maxHeight = $(this).height();
							}
						});
						$(topicsContent).height(maxHeight);
					clearInterval(timer);
					}
				},200);
			}
		},
		dialog:function() {
    const allDialogOpen = document.querySelectorAll(".js-dialog-open");
    for (const dialogOpen of allDialogOpen) {
      const dialogBody = dialogOpen.nextElementSibling;
      const dialogClose = dialogBody.querySelector(".c-dialog-close");
      const youtubeIframe=dialogBody.querySelector(".youtube");
      dialogOpen.addEventListener("click", () => {
        youtubeIframe.setAttribute('src',youtubeIframe.getAttribute('data-src'));
				console.log(true);
        dialogBody.showModal();
      });
      dialogBody.addEventListener("click", (e) => {
        if (e.target === dialogBody) {
          youtubeIframe.setAttribute('src','');
          dialogBody.close();
        }
      });
      dialogClose.addEventListener("click", () => {
        youtubeIframe.setAttribute('src','');
        dialogBody.close();
      });
    }
  },
		setMoreViewBox:function(){
			var elements=document.querySelectorAll('.js-moreviewBox');
			if(elements){
				Array.prototype.forEach.call(elements,function(item){
					moreViewBox(item);
				})
				function moreViewBox(element){
					var prevDevice;
					var device='pc';
					var content=element.querySelector('.js-moreviewBox--content');
					var trigger=element.querySelector('.js-moreviewBox--btn')

					var col_pc=Number(element.getAttribute('data-col'));
					var col_sp=Number(element.getAttribute('data-col-sp'));
					var gap_pc=Number(element.getAttribute('data-gap'));
					var gap_sp=Number(element.getAttribute('data-gap-sp'));
					var row=3;
					var col="";
					var gap="";
					var children=content.querySelectorAll(':scope>li');//出力された子要素
					var height_array=[];//要素高さ格納用
					var counter=0;//もっと見るボタンの押下回数


					if(window.innerWidth<=768){
						prevDevice='pc';
					}
					else {
						prevDevice='sp';
					}
					init();
					if(children.length>row*col){
						setHeightArray();
						setParentHeight();
						setTrigger();
						triggerEvent();
					}
					else {
						$(trigger.parentNode.parentNode).hide();
					}
					window.addEventListener('resize',function(){
						if(window.innerWidth<=768){
							device='pc';
						}
						else {
							device='sp';
						}
						if(prevDevice!=device){
							location.reload();
						}
						init();
						if(children.length>row*col){
							setHeightArray();
							setParentHeight();
						}
						else {
							$(trigger.parentNode.parentNode).hide();
						}
					})

					function init(){
						if(window.innerWidth<=768) {
							col=col_sp;
							gap=gap_sp;
						}
						else {
							col=col_pc;
							gap=gap_pc;
						}
					}

					function setHeightArray(){
						var j=0;
						var l=0;
						var m=0;
						var temp_height_array=[];

						if(row*col<children.length){
						//各行の高さを取得
						for(var i=0;i<children.length;i+=col){
							temp_height_array[j]=children[i].clientHeight;
							j+=1;
						}
						//~行ごとに値を結合
						for(i=(row - 1);i<=temp_height_array.length;i+=row){
							var temp=temp_height_array[i];
							for(m=1;m<row;m+=1){

								if(temp_height_array[i - m]!=undefined){
									temp+=temp_height_array[i - m];
								}
							}

							if(l==0){
								height_array[l]=temp + (gap * (row - 1));
							}

							else {
								height_array[l]=temp + (gap * row);
							}
							l+=1;
						}

						//各高さから全体の高さに結合
						for(i=1;i<height_array.length;i+=1) {
							height_array[i]+=height_array[i - 1];
						}
						}
						else {
							height_array=[];
						}
					}

					//要素の高さをstyleに追加
					function setParentHeight() {
						content.style.height=height_array[counter]+'px';
					}

					//検索時にもっと見るボタンを初期化
					function setTrigger(){
						if(height_array.length==0){
							content.style.height='auto';
							$(content).hide();
						}
						else {
							$(trigger.parentNode.parentNode).fadeIn();
						}
					}
					//もっと見るボタンをクリック時の処理
					function triggerEvent() {
						trigger.addEventListener('click',function(){
							counter +=1;
							setParentHeight();

							if(counter + 1 >= height_array.length){
								content.style.height='auto';
								$(this.parentNode.parentNode).fadeOut();
							}
						})
					}
				}
			}
		},
		defaultFooter:function(){
			var element=document.querySelector('.js-footer');
			if(element){
				var foot=document.querySelector('.l-foot');
				var relative=document.querySelector('.l-relative');
				var tail=document.querySelector('.l-category-top-tail');
				if(!foot && !relative && !tail){
					if(location.pathname.split('/').length>3){
						element.classList.add('l-footer--border');
					}
				}
			}
		},
		switchContent:function(){
			var content=document.querySelector('.js-switch-content');
			if(content){
				var children=content.querySelectorAll(':scope > div');
				var trigger = document.querySelector('.js-switch-select');
				var clear_trigger = document.querySelector('.js-switch-clear');
				var year=trigger.value;
				var def_year=year;
				getParam();
				if(paramArray['year']){
					year=paramArray['year'];
					trigger.value=paramArray['year'];
				}
				else {
					paramArray['year']=year;
				}

				switchYearData(year);
				triggerEvent(trigger);
				clearEvent(clear_trigger,def_year,trigger);
			}


			function getParam(){
				if (paramStr) {
					var temp = paramStr.replace('?', '');
					var temp1 = temp.split('&');
					Array.prototype.forEach.call(temp1, function (item) {
						var temp2 = item.split('=');
						paramArray[temp2[0]] = temp2[1];
					});
				}
			}

			function setParam() {
				var paramStr = '?';
				var paramKeys = Object.keys(paramArray);
				if (paramKeys.length) {
					Array.prototype.forEach.call(paramKeys, function (item, index) {
						if (index !== 0) {
							paramStr += '&';
						}
						paramStr += item + '=' + paramArray[item];
					});
					history.replaceState({}, '', paramStr);
				}
			}


			function triggerEvent(trigger){
				trigger.addEventListener('change',function(){
					switchYearData(this.value);
					paramArray['year']=this.value;
					setParam();

				})
			}

			function clearEvent(clear_trigger,def_year,trigger){
				clear_trigger.addEventListener('click',function(){
					switchYearData(def_year);
					paramArray['year']=def_year;
					setParam();
					trigger.value=def_year;
				})
			}
			function switchYearData(year){
				Array.prototype.forEach.call(children,function(item){
					var id=item.getAttribute('id');
					if(year==id){
						item.style.display='block';
					}
					else {
						item.style.display='none';
					}
				})
			}

		},
    /*・・・・・・・・・・・・・全イベント実行・・・・・・・・・・ */
    allEvent:function(){
      document.addEventListener('DOMContentLoaded',function(){
        Common.initialize();  // DOMツリー読み込み後に初期化する場合
        Common.render();
      });
      window.addEventListener('load', function(){
        Common.onLoad();
      });
      window.addEventListener('scroll',function(){
        Common.scroll();
      });
      window.addEventListener('resize',function(){
				var lastInnerWidth = window.innerWidth ;
				if ( lastInnerWidth != window.innerWidth ) {
					lastInnerWidth = window.innerWidth ;
					Common.resize();
				}
      });
      window.addEventListener('popstate',function(){
        location.reload();
      });
    }
  };
  Common.allEvent();
  globalCommon = Common;
}(
  window.jQuery
));
